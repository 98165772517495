export const PairContractAddress = {
  "WETH-USDT": "0x9a98bffabc0abf291d6811c034e239e916bbcec0",
  "WETH-USDC": "0x716bd8a7f8a44b010969a1825ae5658e7a18630d",
  "WBTC-WETH": "0x97491b65c9c8e8754b5c55ed208ff490b2ee6190",
  "LINK-WETH": "0x407e99b20d61f245426031df872966953909e9d3",
  "TRU-WETH": "0xc92ff322c8a18e38b46393dbcc8a7c5691586497",
  "GTC-WETH": "0x18d3284d9eff64fc97b64ab2b871738e684aa151",
  "WETH-DAI": "0x6c8116abe5c5f2c39553c6f4217840e71462539c",
  "CRV-WETH": "0x6e67bb258b6485b688cbb526c868d4428b634cf1",
  "MATIC-WETH": "0x09b8d86c6275e707155cdb5963cf611a432ccb21",
  "FORTH-WETH": "0x53a4512bbe5083695d8e890789fe1cf6f5686d52",
  "PERP-WETH": "0x33682bfc1d94480a0e3de0a565180b182b71d485",
  "OHM-WETH": "0x65bc5c6a2630a87c2b494f36148e338dd76c054f",
  "RARE-WETH": "0xbff4a47a0f77637f735e3a0ce10ff2bf9be12e89",
  "AAVE-WETH": "0xbb9b86a75ca3115caab045e2af17b0bba483acbc",
  "MM-WETH": "0xb666bfdb553a1aff4042c1e4f39e43852ba9731d",
  "COMP-WETH": "0x0407c810546f1dc007f01a80e65983072d5c6dfa",
  "UNI-WETH": "0xd8dbdb77305898365d7ba6dd438f2663f7d4e409",
  "MKR-WETH": "0x4564a37c88e3b13d3a0c08832dcf88278997e6fe",
  "MTA-WETH": "0xc14e7ec60699a39cfd59bae06168afc2c76f32ac",
  "LQTY-WETH": "0xd930ab15c8078ebae4ac8da1098a81583603f7ce",
  "MVI-WETH": "0x7f92463e24b2ea1f7267aceed3ad68f7a956d2d8",
  "ETH2XFLI-WETH": "0x23c85dca3d19b31f14aeea19beac32c2cb2ffc72",
  "WETH-ENS": "0x5230371a6d5311b1d7dd30c0f5474c2ef0a24661",
  "FLOAT-WETH": "0x85cbed523459b7f6f81c11e710df969703a8a70c",
  "GAMMA-WETH": "0x336d7e0a0f87e2729c0080f86801e6f4becf146f",
};
